@import url('https://fonts.googleapis.com/css?family=Titillium+Web:200,200i,300,300i,400,400i,600,600i,700,700i,900&display=swap');

.App {
  text-align: center;
  font-family: 'Titillium Web', sans-serif;
}

.section {
  background-color: #000000;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

body {
  margin: 0;
  font-family: 'Titillium Web', sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.section header {
  text-align: center;
}

.section .logo{
  width: 35%;
  animation-duration: 1.8s;
  animation-delay: 0.7s;
  animation-iteration-count: infinite;
}

#box {
  height:auto;
  background:#000;
  color:#fff;
  cursor:pointer;
  min-width: 480px;
  margin: 25px 0 0 0;
  max-width: 480px;
  font-size: 18px;
  text-transform: uppercase;
}

#tab {
  height:0px;
  -webkit-transition: all 0.5s linear;
  -moz-transition: all 0.5s linear;
  -ms-transition: all 0.5s linear;
  -o-transition: all 0.5s linear;
  transition: all 0.5s linear;
  overflow: hidden;
  padding: 15px 0 0 0;
}

#tab.opened {
  height:200px;
  -webkit-transition:all 0.5s linear;
  -moz-transition: all 0.5s linear;
  -ms-transition: all 0.5s linear;
  -o-transition: all 0.5s linear;
  transition: all 0.5s linear;
}

.skill {
  float: left;
  margin: 15px 15px 15px 0;
  height: 0;
  text-transform: uppercase;
  font-size: 20px;
}

#box p .svg-inline--fa {
  float: none;
}

#box .svg-inline--fa {
  float: right;
  font-size: 25px;
}

header {
  margin: 0 0 50px 0;
}

footer {
  margin: 50px 0;
}

.credits {
  text-align: center;
  margin: 20px 0;
  font-size: 18px;
}

.components {
  text-align: center;
}

.components .component {
  width: 6%;
}

@media only screen and (max-width: 440px) {
  #box {
    min-width: 200px;
    max-width: 200px;
  }
  #tab.opened {
    height: 350px;
  }
}